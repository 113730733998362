<template>
<div>
    
    <div class="col-md-12">
        <div class="row" style="margin-top: 20px">
            <div class="col-md-6">
            <h3 class="page-header">
                <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                <span>{{setheadding()}} Report</span>
            </h3>
            </div>
            <div class="col-md-6 text-right" v-if="viewno == 1">
            <button @click="refresh()" class="btn btn-refresh">
                <i class="fa fa-refresh"></i>
            </button>
            </div>
            <div class="col-md-6 text-right" v-if="viewno == 2">
            <button @click="back()" class="btn btn-primary">
                <i class="fa fa-backward"></i> Back
            </button>
            </div>
        </div>
    </div>
    <div style="background-color: white" v-if="viewno==1">
        <div class="col-md-12" >
            <div class="panel panel-cascade">
                <div class="panel-body ">                   
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customername" type="text"  />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Zone<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="zoneid">
                                            <option selected="selected" value="">SELECT</option>
                                            <option v-for="item in getmastertype(MASTER_ZONE_TYPES)" :key="item.labelid" :value="item.labelid">{{item.label}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Emi Date: FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="emidatefrom" type="date" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Due Date :FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="duedatefrom" type="date"/>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Bucket: FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="bucketdatefrom" type="text"/>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Remaining: FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="remfrom" type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 CHK">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group CHK">
                                <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="mobile" type="number"  />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Customer LAN</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="manualid" type="text" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Emi Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="emidateto" type="date"  />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Due Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="duedateto" type="date" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Bucket : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="bucketdateto" type="text" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-lg-4 col-md-3 control-label">Remaining : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="remto" type="number" />
                                    </div>
                                </div>
                                <div class="form-group">
        
                                    <div class="col-lg-12 col-md-9">
                                        <button class="btn btn-primary" @click="refresh()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="loginusercheck(159)">
                        <div class="info-box bg-warning text-white">
                            <div class="info-icon bg-primary-dark" style="padding-bottom:5px;"> 
                                <h5>
                                    <i class="fa fa-check-square-o"></i>
                                    Select REC BY
                                </h5>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3" v-for="(item) in employees" v-bind:key="item.id">
                                        <!-- <usercheck :empid="item.id"></usercheck>  {{item.name}} -->
                                        <input type="checkbox" 
                                        :value="item.id"     
                                        id="item.id" 
                                        v-model="checkedCategories" 
                                        @change="check($event)">{{item.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pt-20 panel">
            <div class="flex-between-row panel-body">
                <!-- <div class="">
                <downloadexcel
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    type="csv"
                    name="filename.xls">
                    <strong> GET PDF:</strong>
                    <img src="../../assets/excel.png" width="30" height="30" />
                </downloadexcel>
                </div> -->
                <div class="">
                    <input v-if="!generate"
                    type="button"
                    id="btnprint"
                    class="btn bg-success btn-xs text-white"
                    value="Get PDF"
                    @click="generateReport()"
                />
                <pdf-test v-if="generate" :scheduleloanemi="scheduleloanemi" :overdues="overduefetch" :comments="visitcomment"
                :totalduescount="totalduescount" :totalschedule="totalschedule" :totalemiamount="totalemiamount" :remainingamount="remainingamount"
                ></pdf-test>
                </div>
                <div class="">
                    <span class="control-label">Total Rec:</span>
                    <span class="badge bg-seagreen">
                        <i class="fa fa-inr"></i>{{ totalschedule }}</span>
                </div>
                <!-- <div class="">
                    <span class="control-label">Total Remaining:</span>
                    <span class="badge bg-danger">
                        <i class="fa fa-inr"></i>{{ getsumofremainingamount() }}</span>
                </div> -->
                <div class="">
                    <span class="control-label">Total Over Dues Amount:</span>
                    <span class="badge bg-seagreen font-14"><i class="fa fa-inr"></i>{{totalduescount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Emi Amount:</span>
                    <span class="badge bg-seagreen font-14"><i class="fa fa-inr"></i>{{totalemiamount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Rem. Amount:</span>
                    <span class="badge bg-danger font-14"><i class="fa fa-inr"></i>{{remainingamount}}</span>
                </div>
           
        </div>
       
        </div>
        <div class="col-md-12">
            <div class="col-md-12 mt-20">
                <div class="row">
                    <div class="col-md-2">
                        <strong>Show Rec.</strong>
                    </div>
                    <div class="col-md-2">
                        <select
                        class="form-control col-mb-1"
                        v-model="noofrec"
                        id="rec"
                        @change="refresh()"
                        >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        </select>
                    </div>
                    <div>
                        <button class="btn btn-primary" @click="exportExcel()">Export</button>
                    </div>
                </div>
            </div>
        <div class="panel">
            <div class="table-responsive panel-body">
                <table
                    class="table users-table table-condensed table-hover table-sm table-bordered"
                >
                    <thead class="table-font">
                    <tr>
                        <th>#</th>
                        <th>LAN No.</th>
                        <th>Customer Name</th>
                        <th>Zone</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>Product</th>
                        <th>Emi Amt</th>
                        <th>Late Amt</th>
                        <th>Rem. Amt</th>
                        <th>Emi Date</th>
                        <th>Due Date</th>
                        <th>Message</th>
                        <th>Bucket</th>
                        <th>Day of Delay</th>
                        <th>Fallow By</th>
                        <th>Cust.Type</th>
                        <th>Re-Pay.Type</th>
                        <th v-if="loginusercheck(157)">Acc.Status</th>
                    </tr>
                    </thead>
                    <dues-row v-for="(item,index) in scheduleloanemi" :key="item.id" 
                    :item="item" :index="index" :currentpage="currentpage" :noofrec="noofrec" 
                    :overdues="overduefetch" :comments="visitcomment"></dues-row>
                </table>
            </div>
        </div>
        <div class="col-md-12" style="margin-top: 20px">
            <nav aria-label="Page navigation example" style="text-align: right !important">
            <paginate
                style="margin: 0px !important; float: right"
                :page-count="noofpages"
                :click-handler="clickCallback"
                :prev-text="'&laquo;'"
                :next-text="'&raquo;'"
                :container-class="'pagination'"
            >
            </paginate>
            </nav>
        </div>
        </div>
    </div>
    <sales-details v-if="viewno ==2 "></sales-details>
    <modal name="loanaccount" :width="500" :height="250"  draggable=".window-header">
        <div class="col-md-12 pb-10 text-center">
            <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Update Status</h3>
        </div>
        <div class="col-md-12">
            <label for="remark">Comment</label>
        </div>
        <div class="col-md-12">
            <textarea id="remark" class="form-control " v-model="comments"></textarea>
        </div>
        <div class="col-md-12">
            <p> Update To: {{setaccounttype()}}</p>
        </div>
        <div class="col-md-12 pt-20 pb-20 flex-between-row">
           <button v-if="clickadvice==false" class="btn bg-success text-white btn-sm chkcondition" @click="submitstatus()">Save</button>
            <span v-if="clickadvice" class="spinner"></span>
            <button class="btn bg-danger text-white btn-sm chkcondition" @click="close()">Close</button>
        </div>
        
    </modal>
  
</div>
</template>
<script>
// import downloadexcel from "vue-json-excel";
import Paginate from "vuejs-paginate";
import Constants from "../../components/utilities/Constants.vue";
import moment from "moment";
import SalesDetails from "../salesmanager/SalesDetails.vue";
import { mapGetters } from "vuex";
import DuesRow from "./DuesRow.vue";
import PdfTest from "./PdfTest.vue";
// import DisplayFirstAddress from "../../components/address/DisplayFirstAddress.vue";
// import DisplayMobiles from "../../components/mobile/DisplayMobiles.vue";
// // import OverDueSearch from "./OverDueSearch.vue";
// import VueHtml2pdf from 'vue-html2pdf'
export default {
    mixins: [Constants],
    components: {
        // downloadexcel,
        SalesDetails,
        Paginate,
        DuesRow,
        PdfTest
    },
    data() {
        return {
            checkedCategories:[],
            moment: moment,
            currentpage: 1,
            noofrec: 10,
            searchrec: [],
            mobile:'',
            customername:'',
            manualid:'',
            emidateto:'',
            emidatefrom:'',
            zoneid:'',
            employeeids:'',
            duedatefrom:'',
            duedateto:'',
            bucketdatefrom:'',
            bucketdateto:'',
            totalschedule:0,
            comments:'',
            clickadvice:false,
            pagetypeid:0,
            totalduescount:0,
            totalemiamount:0,
            generate:false,
            overdueamount:0,
            remainingamount:0,
            overduefetch:[],
            loanids:[],
            emiids:[],
            visitcomment:[],
            remto:'',
            remfrom:''
        };
    },
    computed: {
        ...mapGetters(["edititem", "viewno", "scheduleloanemicount", "scheduleloanemi",
            "employees","attributes","accountstatus","loanaccounttype","sumofremainingamount","loggedinuser"]),
        noofpages() {
            return Math.ceil(parseInt(this.scheduleloanemicount) / parseInt(this.noofrec));
        },
    },
    watch:{
        '$route.params.pagetype'() {
            this.refresh()
        }
    },
    mounted() {
        this.refresh();
        this.$store.dispatch('fetchemployees')
        this.$store.dispatch("fetchattributes")
        this.$store.dispatch('fetchsumofremainingamount');
    },
    methods: {
        getsumofremainingamount(){
            let sumamount=0
            if(this.scheduleloanemi!=null){
                this.scheduleloanemi.forEach(element => {
                    if(element.loanemi?.loanaccount!=null){
                        sumamount=sumamount+ parseInt(element.loanemi?.loanaccount.remainingamount)
                    }
                });
            }
            return sumamount
        },
        setaccounttype(){
            if(this.loanaccounttype==0){
                return "Dues Account"
            }else if(this.loanaccounttype==1){
                return "Clear Account"
            }else if(this.loanaccounttype==2){
                return "Defaulter Account"
            }else if(this.loanaccounttype==3){
                return "Legal Account"
            }else if(this.loanaccounttype==4){
                return "Concesation Account"
            }
        },
        setheadding(){
            if(this.pagetypeid==0){
                return "Dues Account"
            }else if(this.pagetypeid==1){
                return "Clear Account"
            }else if(this.pagetypeid==2){
                return "Defaulter Account"
            }else if(this.pagetypeid==3){
                return "Legal Account"
            }else if(this.pagetypeid==4){
                return "Concesation Account"
            }
        },
        submitstatus(){
            if(this.accountstatus!=null){
                let param={comments:this.comments,emiid:this.accountstatus.emiid,
                    accountstatus:this.loanaccounttype}
                window.axios.post('api/emi/comment/create',param)
                    .then((response) => this.processcommentResponse(response.data))
                    .catch((err) => {
                    console.log('', err)
                })
            }
        },
        processcommentResponse(){
            this.$notify({text:'Account Status Updated',type:'Success'})
            this.$modal.hide('loanaccount');
            this.comments=''
            this.refresh();
        },
        close(){
            this.$modal.hide('loanaccount')
            this.comments=""
        },
        check (e) {
            this.$nextTick(() => {
                console.log(this.checkedCategories, e)
            })
            this.refresh()
        },
        
        clickCallback(currentpage) {
            this.currentpage = currentpage;
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        refresh() {
            this.$store.dispatch("fetchmaster")
            setTimeout(() => {
                if(!this.loginusercheck(159)){
                    this.checkedCategories.push(this.loggedinuser?.id)
                }
                this.$store.commit("assignloadingstatus",1)
                let param = {mobile:this.mobile,currentpage:this.currentpage,noofrec:this.noofrec,
                    customername:this.customername,manualid:this.manualid,accountstatus:this.pagetypeid,
                    zoneid:this.zoneid,emidatefrom:this.emidatefrom,emidateto:this.emidateto,
                    empid:this.checkedCategories,duedatefrom:this.duedatefrom,duedateto:this.duedateto,
                    remto:this.remto,remfrom:this.remfrom}

                window.axios.post('api/scheduled/fetchloansemi',param)
                    .then((response) => this.processsearchResponse(response.data))
                    .catch((err) => {
                    console.log('', err)
                });
                console.log("Acccountstatus"+this.pagetypeid)
                let param2 = {mobile:this.mobile,
                    customername:this.customername,manualid:this.manualid,accountstatus:this.pagetypeid,
                    zoneid:this.zoneid,emidatefrom:this.emidatefrom,emidateto:this.emidateto,
                    empid:this.checkedCategories,duedatefrom:this.duedatefrom,duedateto:this.duedateto,
                    remto:this.remto,remfrom:this.remfrom}
                window.axios.post('api/scheduled/fetchLoansEmicount',param2)
                    .then((response) => this.processCountResponse(response.data))
                    .catch((err) => {
                    console.log('', err)
                });
                let para={accountstatus:this.pagetypeid}
                window.axios.post('api/scheduled/loansemi/count',para)
                    .then((response) => this.processTotalCountResponse(response.data))
                    .catch((err) => {
                    console.log('', err)
                });
                window.axios.post('api/emi/overdue/total',param)
                    .then((response) => this.processTotalEmiCountResponse(response.data))
                    .catch((err) => {
                    console.log('', err)
                });
                window.axios.post('api/sum/dues/remainingamount',param)
                    .then((response) => this.processTotalRemainingamountResponse(response.data))
                    .catch((err) => {
                    console.log('', err)
                });
            }, 2000);
        //    this.fetchoverduesbyLoanid()
        },
        processTotalRemainingamountResponse(data){
            this.remainingamount=data
        },
        ProcessResonseFetchOverdue(data){
            this.overduefetch=data
            this.$store.commit("assignloadingstatus",0)
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        bucket(item) {
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item.emidate), "months");
            if (diffInDays < 0) {
                return 0;
            } else {
                return diffInDays;
            }
        },
        processTotalEmiCountResponse(data){
            this.totalduescount=data.overdue
            this.totalemiamount=data.emiamount
            this.$store.commit("assignloadingstatus",0)
        },
        processTotalCountResponse(data){
            console.log(data)
            // this.totalschedule=data
            this.$store.commit("assignloadingstatus",0)
        },
        processsearchResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.totalschedule=data.count
            this.$store.commit("assignscheduleloanemi",data.emis)
            if(data!=null){
                this.loanids = data.emis.map(a => {
                    if(a.loanemi!=null){
                        return a.loanemi.loanaccountid
                    }
                });
                this.emiids=data.emis.map(a=>{
                    return a.emiid
                });
            }
            let param = { loanid: this.loanids.join() };
            this.$http
                .post("api/overdues/emi", param)
                .then((response) => this.ProcessResonseFetchOverdue(response.data))
                .catch((err) => {
                console.log("", err);
            });
            let param1={emiid :this.emiids.join()}
            this.$http
                .post("api/loan/emi/visitcomments/last/record", param1)
                .then((response) => this.ProcessResonseFetchComment(response.data))
                .catch((err) => {
                console.log("", err);
            });
        },
        ProcessResonseFetchComment(data){
            this.visitcomment=data
        },
        processCountResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit("assignscheduleloanemicount",data)
        },
        back() {
            this.$store.commit("assignviewno", 1);
        },
        startDownload() {
            this.$store.commit("assignloadingstatus", 1);
        },
        finishDownload() {
            this.$store.commit("assignloadingstatus", 0);
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.generate=!this.generate
            // console.log(this.scheduleloanemicount)
            // this.$refs.html2Pdf.generatePdf()
        },
        exportExcel() {
            let param = {mobile:this.mobile,currentpage:this.currentpage,noofrec:this.noofrec,
                    customername:this.customername,manualid:this.manualid,accountstatus:this.pagetypeid,
                    zoneid:this.zoneid,emidatefrom:this.emidatefrom,emidateto:this.emidateto,
                    empid:this.checkedCategories,duedatefrom:this.duedatefrom,duedateto:this.duedateto,
                    remto:this.remto,remfrom:this.remfrom}
            this.$http({
                url: 'api/dues/report/exportdata',
                method: 'post',
                responseType: 'blob',
                data: param,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    mode: 'no-cors'
                }
            })
                .then(response => {
                    console.log("response ",response)
                    let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'DueReport.xlsx'
                    link.click()
                    this.flagexl = 0
                })
        },
    }
}
</script>
<style scoped>
.col-lg-8{
    float: right;
}
.panel-body{
    font-size: 14px;
}
.input-small{
    height: 32px;
}
</style>
