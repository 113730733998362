<template>
    <div>
        <div>
            <div v-if="viewno == 1">
                <div class="col-md-12 flex-between-row">
                    <div class="col-md-6">
                        <h3 class="page-header"><i class="fa fa fa-user-md animated bounceInDown show-info"></i> View
                            Leads
                        </h3>
                    </div>
                    <div class="col-md-6 text-r">
                        <button class="btn btn-primary" @click="refresh()"><i class="fa fa-refresh"></i>
                            Referesh</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="info-box  bg-success-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e;padding-top: 27px;">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <div>
                                    <h4>STG:
                                        <!-- <span class="badge bg-danger" style="font-size:medium;">{{ totalleads }}</span> -->
                                    </h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Sales :</label>
                                            <span class="badge bg-danger">{{ this.goals.achievesalestg }}</span>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Leads :</label>
                                            <span class="badge bg-danger">{{ this.goals.achieveleadstg }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-danger-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e ;padding-top: 27px;">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>LTG :
                                    <!-- <span class="badge bg-success" style="font-size:medium;">{{ totalsale }}</span> -->
                                </h4>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Sales :</label>
                                        <span class="badge bg-success">{{ this.goals.achievesaleltg }}</span>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Leads :</label>
                                        <span class="badge bg-success">{{ this.goals.achieveleadltg }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-success-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Leads: <span class="badge bg-danger" style="font-size:medium;">{{ totalleads
                                        }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-danger-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Sale : <span class="badge bg-success" style="font-size:medium;">{{ totalsale
                                        }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="info-box  bg-success-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Scheduled leads: <span class="badge bg-danger" style="font-size:medium;">{{
                                    scheduleleads }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-danger-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Unscheduled leads : <span class="badge bg-success"
                                        style="font-size:medium;">{{
                                            unscheduleleads }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-success-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Fake leads: <span class="badge bg-danger" style="font-size:medium;">{{
                                        fakeleads
                                        }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-danger-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Dispose leads : <span class="badge bg-success" style="font-size:medium;">{{
                                    disposeleads }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-success-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Relevant leads: <span class="badge bg-danger" style="font-size:medium;">{{
                                    relevant
                                        }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box  bg-danger-dark  text-white">
                            <div class="info-icon" style="background-color:#7e7e7e">
                                <i class="fa fa-calendar fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Unrelevant leads : <span class="badge bg-success" style="font-size:medium;">{{
                                    unrelevant }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-cascade">

                            <div class="panel-body ">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-horizontal cascde-forms">

                                            <div class="form-group flex-row CHK">
                                                <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="customername" type="text" value="" />
                                                </div>
                                            </div>
                                            <!-- <div class="form-group flex-row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Address</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customername" type="text" value="" />
                                    </div>
                                </div> -->

                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Entry Date : FROM</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="entrydatefrom" type="date" />
                                                </div>
                                            </div>
                                            <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Dispose Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="disposedatefrom" type="text" />
                                    </div>
                                </div> -->
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Schedule Date :
                                                    FROM</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="scheduledatefrom" type="date" />
                                                </div>
                                            </div>
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Ratings</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select name="" id=""
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="ratings">
                                                        <option :value="0">--Select--</option>
                                                        <option :value="1">--1--</option>
                                                        <option :value="2">--2--</option>
                                                        <option :value="3">--3--</option>
                                                        <option :value="4">--4--</option>
                                                        <option :value="5">--5--</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group flex-row" style="display:none">
                                                <label class="col-lg-4 col-md-3 control-label">Status</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select class="form-control form-cascade-control input-small"
                                                        v-model="isdispose">
                                                        <option value="0">SELECT</option>
                                                        <option value="0">PENDING</option>
                                                        <option value="1">DISPOSE</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Advisor</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="advisorsearch" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Customer Refrence</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select v-model="refrenceid"
                                                        class="form-control delradius form-cascade-control">
                                                        <option :value="0">----Select---</option>
                                                        <option
                                                            v-for="option in getcompanymastertype(COMPANY_CUSTOMER_REFRENCE)"
                                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                            v-text="option.label" />
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">City</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select v-model="city"
                                                        class="form-control delradius form-cascade-control">
                                                        <option :value="0">----Select---</option>
                                                        <option v-for="(item) in getmastertype(MASTER_CITIES)" v-bind:key="item.labelid"
                                                            :value="item.labelid">{{ item.label }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Complaint No.</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="complaintno" type="number" />
                                    </div>
                                </div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6 CHK">
                                        <div class="form-horizontal cascde-forms">
                                            <div class="form-group flex-row CHK">
                                                <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="customermobile" type="number" />
                                                </div>
                                            </div>

                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Entry Date : TO</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="entrydateto" type="date" />
                                                </div>
                                            </div>
                                            <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Dispose Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="disposedateto" type="date" />
                                    </div>
                                </div> -->
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Schedule Date :
                                                    TO</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small"
                                                        v-model="scheduledateto" type="date" value="" />
                                                </div>
                                            </div>





                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Entry By</label>

                                                <div class="col-lg-8 col-md-9">
                                                    <select v-model="entrybyid"
                                                        class="form-control form-cascade-control input-small"
                                                        v-if="loggedinuser?.role < 2">
                                                        <!-- v-for="item in employees.filter(property => property.id == loggedinuser.id)" -->
                                                        <option
                                                            v-for="item in employees"
                                                            :value="item.id" :key="item.id">{{ item.name }} </option>
                                                    </select>
                                                    <select v-model="entrybyid"
                                                        class="form-control form-cascade-control input-small"
                                                        v-if="loggedinuser?.role >= 2">
                                                        <option v-for="filter in employees" :key="filter.id"
                                                            :value="filter.id">
                                                            {{ filter.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Lead View</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select v-model="isscheduled"
                                                        class="form-control form-cascade-control input-small">

                                                        <option :value="0">Pending Leads</option>
                                                        <option :value="1">Scheduled Leads</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group flex-row">
                                                <label class="col-lg-4 col-md-3 control-label">Nature Type</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select v-model="naturetype"
                                                        class="form-control delradius form-cascade-control">
                                                        <option :value="0">----Select---</option>
                                                        <option
                                                            v-for="option in getcompanymastertype(COMPANY_LEAD_NATURE)"
                                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                            v-text="option.label" />
                                                    </select>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-8 row">
                                        <div class="col-md-3" style="">
                                            <input type="submit" @click="search()" value="Search"
                                                class="btn bg-primary text-white btn-md">
                                        </div>
                                        <div class="col-md-3" style="">
                                            <input type="submit" @click="initial()" value="Clear"
                                                class="btn bg-danger text-white btn-md">
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="loginusercheck(272)">
                                        <div class="col-md-12 ">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <select @change="chooseUser()" v-model="userrecord"
                                                        class="form-control">
                                                        <option value="0">Select Page</option>
                                                        <option v-for="item in noofuser" :key="item" :value="item">{{
                                                            item
                                                            }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <button class="btn btn-success" @click="sendMessage()">
                                                        <i class='bx bxl-whatsapp'
                                                            style="font-size: 24px;position: relative;top: 5px;left: -5px;"></i>WhatsApp
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 card-body ft-14 mt-20 card-type">
                        <div class="col-md-12 flex-between-row">
                            <h5 style="color:green">Conversation Search</h5>
                            <div>
                                <label for="">Searched Conv : </label>
                                <span class="badge bg-primary">{{ searchconvercount }}</span>
                            </div>
                        </div>
                        <div class="row mt-30">
                            <div class="col-md-4 row" v-if="loggedinuser?.role < 2">
                                <div class="col-md-5">
                                    <strong>Enter By</strong>
                                </div>
                                <div class="col-md-7">
                                    <select class="form-control form-cascade-control input-small" v-model="enteredbyid">
                                        <option :value="0">-----Select------</option>
                                        <option
                                            v-for="item in employees.filter(property => property.id == loggedinuser.id)"
                                            :value="item.id" :key="item.id">{{ item.name }} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4 row" v-if="loggedinuser?.role >= 2">
                                <div class="col-md-5">
                                    <strong>Enter By</strong>
                                </div>
                                <div class="col-md-7">
                                    <select class="form-control form-cascade-control input-small" v-model="enteredbyid">
                                        <option :value="0">-----Select------</option>
                                        <option v-for="item in employees" :value="item.id" :key="item.id">{{ item.name
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4 row">
                                <div class="col-md-6">
                                    <strong>Conversation Date From</strong>
                                </div>
                                <div class="col-md-6">
                                    <input type="date" class="form-control form-cascade-control input-small"
                                        v-model="entrydatefromconv">
                                </div>
                            </div>
                            <div class="col-md-4 row">
                                <div class="col-md-6">
                                    <strong>Conversation Date To</strong>
                                </div>
                                <div class="col-md-6">
                                    <input type="date" class="form-control form-cascade-control input-small"
                                        v-model="entrydatetoconv">
                                </div>
                            </div>
                            <div class="col-md-12 flex-around-row text-right">
                                <button type="button" class="btn bg-primary text-white username btnsearch"
                                    v-if="searchconvercount >= 1" @click="viewconversation()"
                                    style="margin-top: 31px;">View
                                    Conversation</button>
                                <button type="button" class="btn bg-success text-white username btnsearch"
                                    @click="conversationfetch()" style="margin-top: 31px;">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-20 pb-10 boxshow">
                    <div class="flex-between-row">
                        <div class="col-md-4 flex-between-row">
                            <div class="">
                                <strong>Show Rec.</strong>
                            </div>
                            <div>
                                <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="search()">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                </select>
                            </div>
                            <div class="">
                                <button class="btn btn-primary" @click="exportExcel()">
                                    Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="panel">
                        <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                            <table class="table users-table table-condensed table-hover table-bordered table-sm">
                                <thead class="table-font" style="font-size:13px;">
                                    <tr>
                                        <th>#</th>
                                        <th class="visible-lg">NAME</th>
                                        <th>MOBILE</th>
                                        <th>Alternate Mobile</th>
                                        <th>ADDRESS</th>
                                        <th>CITY</th>
                                        <th>D.O.B</th>
                                        <th>Cust Ref.</th>
                                        <th>Remark</th>
                                        <th>SCHEDULED DATE</th>
                                        <th>Advisor</th>
                                        <th>Cust. Ref</th>
                                        <th>ENTRY BY<br>ENTRY DATE</th>
                                        <!-- <th>FALLOW BY</th> -->
                                        <th>Ratings</th>
                                        <th v-if="loginusercheck(193)">Conversation</th>
                                        <th>Sale History</th>
                                        <th v-if="loginusercheck(196)">Not Rel/Relevant</th>
                                        <th>Edit</th>
                                        <th>Nature Lead</th>
                                    </tr>
                                </thead>
                                <scheduled-leads-row v-for="(item, index) in leads" :key="item.id" :item="item"
                                    :index="index"></scheduled-leads-row>
                            </table>
                        </div>
                        <div class="col-md-12" style="margin-top: 20px">
                            <nav aria-label="Page navigation example" style="text-align: right !important">
                                <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                    :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <new-lead v-if="viewno == 2"></new-lead>
            <conversation :conversation="searchconversation" :count="searchconvercount" v-if="viewno == 3"
                :leadtype="1">
            </conversation>

            <modal name="leadfakemodal" :width="400" :height="250" draggable=".window-header">
                <div class="col-md-12 pb-10 text-center">
                    <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">
                        Lead Fake/Relevant Update
                    </h3>
                </div>
                <div class="col-md-12">
                    <label for="remark">Conversation</label>
                </div>
                <div class="col-md-12">
                    <textarea id="remark" class="form-control " v-model="detail"></textarea>
                </div>

                <div class="col-md-12 pt-20 pb-20" style="display:flex;flex-direction:row">
                    <button class="btn bg-primary text-white btn-sm chkcondition" style="margin-right:10px;"
                        @click="updatefakedata()">
                        <span v-if="edititem?.isrelevant == 1">Relevant</span>
                        <span v-else>Not Relevant</span>
                    </button>
                    <button class="btn bg-danger text-white btn-sm chkcondition"
                        @click="closefakemodal()">Close</button>
                </div>
            </modal>

        </div>
        <multiple-whats-app-templates v-if="viewno == 4"> </multiple-whats-app-templates>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NewLead from './NewLead.vue'
import Paginate from "vuejs-paginate";
import ScheduledLeadsRow from './ScheduledLeadsRow.vue'
import Constants from '../../components/utilities/Constants'
import Conversation from '../conversation/Conversation.vue'
import MultipleWhatsAppTemplates from '../whatsapp/MultipleWhatsAppTemplates.vue';
export default {
    components: { ScheduledLeadsRow, NewLead, Conversation, Paginate, MultipleWhatsAppTemplates },
    mixins: [Constants],
    data() {
        return {
            // leads:[],
            customername: '',
            customermobile: '',
            followbyid: 0,
            entrydatefrom: '',
            disposedatefrom: '',
            requesttype: 5,
            scheduledatefrom: '',
            entrybyid: 0,
            disposedateto: '',
            entrydateto: '',
            scheduledateto: '',
            leadtype: 0,
            isdispose: 0,
            complaintno: '',
            totalleads: 0,
            ratings: 0,
            searchconvercount: 0,
            entrydatefromconv: '',
            entrydatetoconv: '',
            isscheduled: 0,
            advisorsearch: '',
            totalsale: 0,
            // totalleads: 0,
            fakeleads: 0,
            scheduleleads: 0,
            unscheduleleads: 0,
            relevant: 0,
            unrelevant: 0,
            detail: '',
            refrenceid: 0,
            city:0,
            naturetype: 0,
            disposeleads: 0,
            searchconversation: [],
            noofrec: 10,
            currentpage: 1,
            rows: 0,
            enteredbyid: 0,
            goals: [],
            currentyear: new Date().getFullYear(),
            currentmonth: new Date().getMonth() + 1,
            userrecord: 0,
            noofuser: 0,
            alluser: [],
            noofrecords: 1000
        }
    },
    mounted() {
        this.refresh();
        this.search()
    },
    computed: {
        noofpages() {
            return Math.ceil(parseInt(this.rows) / parseInt(this.noofrec))
        },
        // noofuser() {
        //     return Math.ceil(parseInt(this.alluser?.length) / parseInt(this.noofrecords))
        // },
        ...mapGetters(['employees', 'leads', 'loggedinuser', 'leadsedit ', 'viewno', 'edititem', 'usermobiles'])
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage;
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        exportExcel() {
            this.flagexl = 1
            let param = {
                isdispose: 0,
                customername: this.customername,
                name: this.customername,
                customermobile: this.customermobile,
                followbyid: this.followbyid,
                entrydatefrom: this.entrydatefrom,
                disposedatefrom: this.disposedatefrom,
                requesttype: 5,
                scheduledatefrom: this.scheduledatefrom,
                entrybyid: this.entrybyid,
                disposedateto: this.disposedateto,
                entrydateto: this.entrydateto,
                scheduledateto: this.scheduledateto,
                complaintno: this.complaintno,
                leadtype: this.leadtype,
                isschedule: this.isscheduled,
                ratings: this.ratings,
                advisorsearch: this.advisorsearch,
                refrenceid: this.refrenceid,
                city: this.city,
                naturetype: this.naturetype,
                noofrec: this.noofrec,
                currentpage: this.currentpage,
            }
            this.$http({
                url: 'api/leads/exportdata',
                method: 'post',
                responseType: 'blob',
                data: param,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    mode: 'no-cors'
                }
            })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'lead_report.xlsx'
                    link.click()
                    this.flagexl = 0
                })

        },
        refresh() {
            this.initial()
            this.$store.dispatch("fetchemployees")
            this.$store.dispatch("fetchcompanymaster")
            let param = {
                isdispose: 0, requesttype: 5, isschedule: this.isscheduled, noofrec: this.noofrec,
                currentpage: this.currentpage, enteredbyid: this.entrybyid
            }
            this.$store.commit('assignloadingstatus', 1)
            this.$http.post('api/leads/fetchrecords', param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post('api/leadssales/totalcount')
                .then((response) => this.processLeadSaleTotalResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post('api/leads/total/count', param)
                .then((response) => this.processLeadTotalResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.termGoals();
        },

        processLeadSaleTotalResponse(data) {
            this.totalsale = data
        },
        processLeadTotalResponse(data) {
            this.totalleads = data.leadscount
            this.fakeleads = data.fakeleads
            this.scheduleleads = data.scheduleleads
            this.unscheduleleads = data.unscheduleleads
            this.disposeleads = data.disposeleads
            this.relevant = data.relevant
            this.unrelevant = data.unrelevant
        },
        search() {
            let param = {
                isdispose: 0,
                customername: this.customername,
                name: this.customername,
                customermobile: this.customermobile,
                followbyid: this.followbyid,
                entrydatefrom: this.entrydatefrom,
                disposedatefrom: this.disposedatefrom,
                requesttype: 5,
                scheduledatefrom: this.scheduledatefrom,
                entrybyid: this.entrybyid,
                disposedateto: this.disposedateto,
                entrydateto: this.entrydateto,
                scheduledateto: this.scheduledateto,
                complaintno: this.complaintno,
                leadtype: this.leadtype,
                isschedule: this.isscheduled,
                ratings: this.ratings,
                advisorsearch: this.advisorsearch,
                refrenceid: this.refrenceid,
                city:this.city,
                naturetype: this.naturetype,
                noofrec: this.noofrec,
                currentpage: this.currentpage,
            }
            this.$store.commit('assignloadingstatus', 1)
            this.$http.post('api/leads/fetchrecords', param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.termGoals();
            let param1 = {
                enteredbyid: this.entrybyid, requesttype: 5
            }
            this.$http.post('api/leads/fetchrecords/all', param)
                .then((response) => this.processUserLeadResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post('api/leadssales/totalcount', param1)
                .then((response) => this.processLeadSaleTotalResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post('api/leads/total/count', param1)
                .then((response) => this.processLeadTotalResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            
        },
        termGoals() {
            let param2 = {
                userid: this.entrybyid,
                month: this.currentmonth,
                year: this.currentyear,
            }
            this.$http.post('api/targetgoal/get', param2)
                .then((response) => this.processTermGoalsResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },

        processTermGoalsResponse(data) {
            this.goals = data
            console.log("this goals data")
            console.log(this.goals)
        },
        processLeadResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            this.$store.commit("assignleads", data.lead)
            this.rows = data.count

        },
        processUserLeadResponse(data) {
            this.rows = data.count
            let userlead = data.userlead
            let mobiles = []
            userlead?.forEach(element => {
                if (element.customermobile != null && element.customermobile != '') {
                    mobiles.push({
                        "id": element.id,
                        "customername": element.customername,
                        "mobile": element.customermobile
                    })
                }
            });
            this.alluser = mobiles

            this.noofuser = Math.ceil(parseInt(this.alluser?.length) / parseInt(this.noofrecords))
            // this.$store.commit('assignusermobiles', mobiles)
            this.$store.commit('assignuserids', null)
            // console.log('User Mobiles', this.alluser)
            // console.log('noofuser', this.noofuser)
        },

        conversationfetch() {
            let param = { requesttype: 5, enteredbyid: this.enteredbyid, entrydatefrom: this.entrydatefromconv, entrydateto: this.entrydatetoconv }
            this.$http.post("api/conversation/fetch/byrequest", param)
                .then((response) => this.ProcessConversation(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        ProcessConversation(data) {
            this.searchconvercount = data.count
            this.searchconversation = data.conversation
        },
        initial() {
            this.customername = ''
            this.customermobile = ''
            this.followbyid = 0,
                this.entrydatefrom = ''
            this.disposedatefrom = ''
            this.scheduledatefrom = ''
            this.entrybyid = 0
            this.disposedateto = ''
            this.entrydateto = ''
            this.scheduledateto = ''
            this.leadtype = 0
            this.isdispose = 0
            this.complaintno = '',
                this.ratings = '',
                this.entrydatefromconv = ''
            this.entrydatetoconv = ''
            this.advisorsearch = ''
            this.refrenceid = 0
            this.city=''
            this.noofuser = 0
        },
        updatefakedata() {
            let param = { id: this.edititem?.id, column: 'isrelevant', value: this.edititem?.isrelevant == 0 ? 1 : 0 }
            this.$http.post('api/leads/updatefield', param)
                .then((response) => this.processFakeDetail(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processFakeDetail() {
            this.$modal.hide('leadfakemodal')
            let param = {
                leadid: this.edititem?.id, detail: this.detail,
                requesttype: 5, isfake: this.edititem?.isrelevant == 0 ? 1 : 0
            }
            this.$http.post('api/leads/customerconversation', param)
                .then((response) => this.processCOnverDetail(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        closefakemodal() {
            this.$modal.hide('leadfakemodal')
        },
        processCOnverDetail() {
            this.refresh()
            this.detail = ''
            this.$store.commit('assignedititem', [])
        },
        viewconversation() {
            this.$store.commit('assignviewno', 3);
        },
        sendMessage() {
            if (this.usermobiles?.length > 0) {
                this.$store.commit('assignloadingstatus', 1)
                this.$store.commit('assignedititem', null)
                setTimeout(() => {
                    this.$store.commit('assignviewno', 4)
                    this.$store.commit('assignloadingstatus', 0)
                }, 500);
            } else {
                alert('Select page no.')
            }
        },
        chooseUser() {
            let value = this.alluser?.slice((this.userrecord - 1) * this.noofrecords, (this.userrecord - 1) * this.noofrecords + this.noofrecords);
            this.$store.commit('assignusermobiles', value)
        }
    },
}
</script>
<style>
.flex-row {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
</style>