<template>
    <div class="col-md-12">
        <div class="col-md-12 mt-20">
            <div class="col-md-12 row">
                <div class="col-md-9">
                    <h3 class="page-header">
                        <i class="fa fa fa-rupee animated bounceInDown show-info" style="font-size: 24px;"></i>
                        Expense Head List
                    </h3>
                </div>
                <!-- <div class="col-md-3" >
                    <button class="btn btn-success" @click="addNew()">+ Add New</button>
                    <button class="btn btn-info" style="margin-left: 10px;" @click="refresh()">Refresh</button>
                </div> -->
            </div>
        </div>
        <div >
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <label >Expense Head</label>
                        <select class="form-control" v-model="expenseheadid">
                            <option :value="0">Select</option>
                            <option :value="1" disabled>Salary</option>
                            <option :value="2" disabled>Advance</option>
                            <option :value="3">Rent</option>
                            <option :value="4">Electricity Bill</option>
                            <option :value="5">Fooding</option>
                            <option :value="6">Transportation</option>
                            <option :value="7">Mobile bill</option>
                            <option :value="8">Recharge</option>
                            <option :value="9">Marketing Expense</option>
                            <option :value="10">Others Expense</option>
                            <option :value="11">Loan</option>
                            <option :value="12">Bank Deposit</option>
                        </select>

                        <!-- <input type="text" class="form-control" v-model="expensename" placeholder="Expense Head"/> -->
                    </div>
                    <div class="col-md-4">
                        <label >Expense Sub Head</label>
                        <input type="text" class="form-control" v-model="expensesubhead" placeholder="Expense Head"/>
                    </div>
                    <div class="col-md-3 mt5">
                            <button class="btn btn-success mt-20" @click="addExpenseHead()">Save</button>
                            <button class="btn btn-danger mt-20 ml-5" @click="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-10" >
            <table class="table">
                <thead>
                    <th>#</th>
                    <th>Expense Head</th>
                    <th>Expense Sub Head</th>
                    <th>Created At</th>
                    <th>Edit</th>
                </thead>
                <tbody>
                    <tr v-for="item,index in expenseheads" :key="item.id">
                        <td>{{ index+1 }}</td>
                        <td>
                            <span>{{ EXPENSE_HEADS.find(o=>o.id==item.expenseheadid)?.name }}</span>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>{{ moment(item.created_at).format('YYYY-MM-DD') }}</td>
                        <td><button class="badge bg-primary" @click="editFun(item)"> <i class='bx bxs-edit'></i> </button></td>
                    </tr>
                </tbody>
            </table>
        </div>
       
    </div>
</template>
<script>
import axios from 'axios'
import moment  from 'moment'
import Constants from '../../components/utilities/Constants.vue';

export default {
    mixins:[Constants],
    data() {
        return {
            expensetype:1, //Direct expense
            expenseheads:[],
            expenseheadid:0,
            expensesubhead:'',
            moment:moment,
            id:0
        }
    },
    mounted() {
        this.refresh()
    },
    computed: {

    },
    methods: {
        
        addExpenseHead() {
            if(this.expensesubhead=='' ){
                alert('Enter expense sub head name')
            }else {
                this.$store.commit('assignloadingstatus',1)
                let param = { name: this.expensesubhead, expensetype: 1,expenseheadid:this.expenseheadid,id:this.id }
                axios.post('api/expense/sub/head/createupdate', param)
                    .then(response => this.processAddexpeneResponse(response.data))
                    .catch(error => console.log(error));
            }
		},
        processAddexpeneResponse(data){
            console.log(data);
            this.refresh()
            this.cancel()
            this.$store.commit('assignloadingstatus',0)
        },
        refresh() {
            this.$store.commit('assignloadingstatus',1)
			axios.post('api/expense/sub/head/fetch')
				.then(response => this.processHeadResponse(response.data))
				.catch(error => this.showError(error));
		},
		processHeadResponse(data) {
			this.expenseheads = data.subheads
            this.$store.commit('assignloadingstatus',0)

		},
        cancel(){
            this.expensesubhead=''
            this.expenseheadid = 0
        },
        editFun(item){
            console.log(item)
            this.expensesubhead = item.name
            this.expenseheadid = item.expenseheadid
            this.id = item.id
        }
    },
}
</script>