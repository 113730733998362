<template>
<div style="background-color:white;">
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-list-alt animated bounceInDown show-info"></i>
                 Deactive Advisors
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                    </div>
                </div>
           </div>
        </div>
    </div>
    <div v-if="viewno==1">
        <div class=" panel pb-20">
            <div class="col-md-12 flex-center-row">
                <label class="search-icon"> <i class='bx bx-search-alt-2'></i></label>
                <input  type="text" style="padding-left: 40px;" placeholder="Search Customer Id and Name" class="form-control form-cascade-control input-small search-txt" v-model="search">
                <button @click="refresh()" style="margin-left:10px;" class="btn bg-success text-white username btnsearch"  type="button">Search</button>
            </div>
        </div>
        <!-- <div class=" panel pb-20">
            <div class="col-md-12 ">
                <div class="col-md-2"><button class="btn btn-success" @click="openmessage()">Message<img src="../../assets/messagebird.gif" height="40" width="60"></button></div>
            </div>
        </div> -->
        <!-- <div class="flex-start-between">
            <div class="col-md-4 ">
                <label><strong>From Date</strong></label>
                <input type="date" class="form-control form-cascade-control" v-model="fromdate">
            </div>
            <div class="col-md-4 ">
                <label><strong>To Date</strong></label>
                <input type="date" class="form-control form-cascade-control" v-model="todate" @change="refresh()">
            </div>
            <div class="col-md-4">
                 <label><strong>Created</strong></label>
                <select v-model="advisorcreatorid"  class="form-control form-cascade-control" @change="refresh()">
                    <option value="">Select</option>
                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                </select>
            </div>
        </div> -->
        <div class="flex-start-row">
            <label for=""><strong>Total Deactive Advisors: </strong> {{deactiveadvisorcount}}</label>
        </div>
        <div class="col-md-12 table-responsive pt-20">
            <div class="  panel">
                <div class="  panel-body" style="overflow-x:auto;padding-left:0px">
                    <table class="table tapble-bordered table-hover table-striped display">
                        <thead class="table-font" style="font-size:13px;">
                            <tr>
                                <th>#</th>
                                <th class="visible-lg">CUSTOMER ID</th>
                                <th class="visible-lg" >NAME</th>
                                <th>Downline</th>
                                <th >CONTACT</th>
                                <th >Date Of Activation</th>
                                <th>Created By</th>
                                <th >Commission %</th>
                                <th>Total BALANCE</th>
                                <th>View Details</th>
                                <th>Pay Advisor</th>
                                <th>DeActive</th>
                            </tr>
                        </thead>
                        <advisor-row v-for="(item,index) in deactiveadvisors" v-bind:key="item.id" :item="item" 
                        :index="index"  :currentpage="currentpage" ></advisor-row>
                    </table>
                </div>
            </div>
        </div>
        <!-- <modal name="greetmessage" :width="500" :height="350"  draggable=".window-header">
            <div class="col-md-12 pb-10 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Greet Advisors</h3>
            </div>
            <div class="col-md-12">
                <label for="remark">Remark</label>
            </div>
            <div class="col-md-12">
                <textarea id="remark" class="form-control " v-model="remark"></textarea>
            </div>
        </modal> -->
    </div>
        <down-line v-if="viewno==3"></down-line>
        <commission-details v-if="viewno==4"></commission-details>
        <customer-detail v-if="viewno==5"></customer-detail>
        <customer-form v-if="viewno==6"></customer-form>
</div>
</template>
<script>
import Constants from '../../components/utilities/Constants.vue'
import DownLine from './DownLine.vue'
import { mapGetters } from 'vuex'
import CommissionDetails from './CommissionDetails.vue'
import AdvisorRow from './AdvisorRow.vue'
import CustomerDetail from '../customer/CustomerDetail.vue'
import CustomerForm from '../customer/CustomerForm.vue'
export default {
    mixins:[Constants],
    components:{DownLine,CommissionDetails,AdvisorRow,CustomerDetail,CustomerForm},
    data() {
        return {
            ledgerbal:[],
            search:'',
            advisorcreatorid:'',
            fromdate:'',
            todate:'',
            remark:'',
            deactiveadvisorcount:0
        }
    },
    computed:{
		...mapGetters([
		    'viewno','deactiveadvisors','customers','employees'
		]),
	},
    mounted(){
        this.refresh();
	},
    methods:{
        
        openmessage(){
            this.$modal.show('greetmessage')
        },
       back(){
            this.$store.commit('assignviewno',1)
        },
        processledgerResponse(data){
            this.ledgerbal=data
        },
        refresh(){
            this.$store.dispatch('fetchemployees')
            //this.$store.dispatch('fetchcustomers')
            this.$store.commit("assignloadingstatus",1)
            let param={search:this.search,advisorcreatorid:this.advisorcreatorid,fromdate:this.fromdate,todate:this.todate}
            // this.$store.dispatch('fetchadvisors',param)
            this.$http.post('api/fetch/Deactive/advisor/remainingbalance',param)
            .then((response) => this.processadvisorResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.$http.post('api/users/deactive/fetchdeadvisorcount',param)
            .then((response) => this.processadvisorcountResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processadvisorResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assigndeactiveadvisors',data)
        },
        processadvisorcountResponse(data){
            this.deactiveadvisorcount=data
        },
       
    }
}
</script>
<style scoped>
.search-icon{
    text-align: right;
    margin-top: 15px;
    position: absolute;
    left: 28%;
    font-size: 24px;
}
.search-txt{
    width: 42%;
}
</style>