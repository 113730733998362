<template>
    <div>
        <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="true"
            margin="[10,10,10,10]" filename="Sales" :paginate-elements-by-height="1000" :pdf-quality="2" pdf-format="A4"
            pdf-orientation="portrait" pdf-content-width="auto" :manual-pagination="true" @progress="onProgress($event)"
            @beforeDownload="beforeDownload($event)" @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
            <section slot="pdf-content">
                <section class="pdf-item">
                    <div class="col-md-12" v-if="viewno == 1 || viewno == 2">
                        <div class="row">
                            <div class="col-md-6">
                                <h3 class="page-header">
                                    <i class="fa fa fa-user animated bounceInDown show-info"></i>
                                    View Sales
                                </h3>
                            </div>
                            <div class="col-md-6  flex-row content-end" v-if="isprint == 0">
                                <div class="text-right" style="margin-right:25px" v-if="edititem != null">
                                    <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i>
                                        Back</button>
                                </div>
                                <div class="text-right" v-if="viewno == 1" style="margin-right:25px">
                                    <button @click="refresh()" class="btn btn-refresh"><i
                                            class="fa fa-refresh"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="viewno == 1">
                        <div class="panel panel-cascade" v-if="searchdetails & isprint == 0">
                            <div class="panel-body">
                                <div class="form-horizontal cascde-forms">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label><strong>From Date</strong></label>
                                            <input type="date" class="form-control form-cascade-control input-small"
                                                v-model="fromdate">
                                        </div>
                                        <div class="col-md-4">
                                            <label><strong>To Date</strong></label>
                                            <input type="date" class="form-control form-cascade-control input-small"
                                                v-model="todate">
                                        </div>
                                        <div class="col-md-4">
                                            <label><strong>Lan No.</strong></label>
                                            <input type="text" @keypress.enter="search()"
                                                class="form-control form-cascade-control input-small"
                                                v-model="searchstring">
                                        </div>
                                    </div>
                                    <div class="row mt-10">
                                        <div class="col-md-4">
                                            <label><strong>Product</strong></label>
                                            <!-- <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="product"> -->
                                            <select class="form-control form-cascade-control input-small .dropdown-menu"
                                                v-model="productid">
                                                <option value="">All</option>
                                                <option v-for="item in categorylist" :key="item.id" :value="item.id">{{
                                                    item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <label><strong>Sale By Id</strong></label>
                                            <div style="position: relative;">
                                                <input type="text" class="form-control form-cascade-control input-small"
                                                    @input="referralSearch()" v-model="generatedid">
                                                <span class="suffix" v-if="referraluser != null">{{ referraluser.name
                                                    }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label><strong>Customer Name</strong></label>
                                            <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="customername">
                                        </div>

                                    </div>
                                    <div class="row mt-10">
                                        <div class="col-md-4">
                                            <label><strong>Mobile No.</strong></label>
                                            <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="mobileno">
                                        </div>
                                        <div class="col-md-4">
                                            <label><strong>Serial no.</strong></label>
                                            <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="serialno">
                                        </div>
                                        <div class="col-md-4">
                                            <label><strong>Financial Year</strong></label>

                                            <select class="form-control form-cascade-control input-small"
                                                v-model="selectedfy">
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4 flex-around-row ">
                                            <button @click="search()" class="btn bg-success text-white"
                                                style="margin-top:24px;" type="button">Search</button>
                                            <button @click="cancel()" class="btn bg-danger text-white"
                                                style="margin-top:24px;" type="button">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 small_blog">
                            <div class="col-md-12 row ">
                                <div class="col-md-11 row">
                                    <div class="col-md-4">
                                        <div class="small_blog">
                                            <label>Total Sale : </label>
                                            <span class="total">
                                                {{ totalsalevalue ?? 0 }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="small_blog">
                                            <label>Total Remaining : </label>
                                            <span class="total">
                                                {{ totalremaining ?? 0 }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="small_blog">
                                            <label>Total No. of Bills : </label>
                                            <span class="total">
                                                {{ totalsalecount ?? 0 }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-5 row">
                                    <div class="col-md-6">
                                        <label>Sale : From</label>
                                        <input type="date" class="form-control" v-model="salefrom" />
                                    </div>
                                    <div class="col-md-6">
                                        <label>Sale : To</label>
                                        <input type="date" class="form-control" v-model="saleto" />
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-1 mt-20" v-if="isprint == 0">
                                        <button class="btn btn-success username" @click="fetchTotalSale()">Total</button>
                                    </div>
                                    <div :class="isprint == 0 ? 'col-md-5' : 'col-md-6'">
                                        <div class="small_blog">
                                            <label>Total sale : </label>
                                            <span class="total ml-5">
                                                <i class='bx bx-rupee'></i>
                                                {{ totalsale }}
                                            </span>
                                        </div>
                                    </div> -->
                                <div class="col-md-1 text-end mt-20">
                                    <button class="pdf" @click="printTransaction()"> <i class="bx bxs-file-pdf"
                                            style="font-size:40px"></i></button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-20" v-if="isprint == 0">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <strong>Show Rec.</strong>
                                        </div>
                                        <div class="col-md-8">
                                            <select class="form-control col-mb-1" v-model="noofrec" id="rec"
                                                @change="refresh()">
                                                <option value=10>10</option>
                                                <option value=25>25</option>
                                                <option value=50>50</option>
                                                <option value=100>100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <!-- <div class="col-md-4 text-end">
                                    <button class="btn pdf" @click="generateReport()"> <i class="bx bxs-file-pdf"
                                            style="font-size:40px"></i></button>
                                </div> -->

                            </div>
                        </div>
                        <div class="col-md-12 table-responsive" style="padding:0px;">
                            <div class="panel">
                                <div class="panel-body table" style="overflow-x:auto;padding-left:0px">
                                    <table class="table table-bordered table-hover table-striped display">
                                        <thead class="table-font">
                                            <tr>
                                                <th>#</th>
                                                <th>Lan No.</th>
                                                <th>Sales No.</th>
                                                <th>Customer Id</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Product Detail</th>
                                                <th>Grand Total</th>
                                                <th>Sale Value</th>
                                                <th>Remaining</th>
                                                <th>Delivered By</th>
                                                <th>Plan</th>
                                                <th>Sale Date</th>
                                                <th>Sale By</th>
                                                <th>Entry By</th>
                                                <th>Status</th>
                                                <th v-if="loginusercheck(109)">Edit</th>
                                                <th v-if="loginusercheck(110)">Print</th>
                                                <th v-if="loginusercheck(146)">Close Sale</th>
                                                <th v-if="loginusercheck(145)">Delete</th>
                                            </tr>
                                        </thead>
                                        <view-sales-row v-for="(item, index) in transactiondetails" v-bind:key="item.id"
                                            :item="item" :index="index"></view-sales-row>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12" style="margin-top: 20px" v-if="isprint == 0">
                            <nav aria-label="Page navigation example" style="text-align: right !important">
                                <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                    :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div>
                    </template>
                </section>
            </section>
        </vue-html2pdf>

        <sales-details v-if="viewno == 2"></sales-details>
        <sales-manager v-if="viewno == 3"></sales-manager>
        <invoice v-if="viewno == 4"></invoice>
        <modal name="closedsaleaccount" :width="500" :height="250" draggable=".window-header">
            <div class="col-md-12 pb-10 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Close Sale</h3>
            </div>
            <div class="col-md-12">
                <label for="remark">Comment</label>
            </div>
            <div class="col-md-12">
                <textarea id="remark" class="form-control " v-model="comments"></textarea>
            </div>
            <div class="col-md-12">
                <p> *Closed Sale will Show in Accounts: {{ setaccounttype() }}</p>
            </div>
            <div class="col-md-12 pt-20 pb-20 flex-between-row">
                <button v-if="clickadvice == false" class="btn bg-success text-white btn-sm chkcondition"
                    @click="submitstatus()">Save</button>
                <span v-if="clickadvice" class="spinner"></span>
                <button class="btn bg-danger text-white btn-sm chkcondition" @click="close()">Close</button>
            </div>

        </modal>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Paginate from "vuejs-paginate";
import Constants from '../../components/utilities/Constants.vue'
import SalesDetails from './SalesDetails.vue'
import SalesManager from './SalesManager.vue'
import Invoice from '../invoice/Invoice.vue'
import ViewSalesRow from './ViewSalesRow.vue';
import debounce from 'lodash.debounce';
import VueHtml2pdf from 'vue-html2pdf'

export default {
    mixins: [Constants],
    components: { SalesDetails, SalesManager, Invoice, Paginate, ViewSalesRow, VueHtml2pdf },
    data() {
        return {
            moment: moment,
            transactiondetails: [],
            fromdate: '',
            todate: '',
            // fromdate: new Date().getFullYear()+(new Date().getmonth()+1)+new Date().getDate(),
            // todate: new Date().getFullYear() + (new Date().getmonth()+2) + new Date().getDate(),
            details: [],
            searchstring: '',
            searchdetails: true,
            currentpage: 1,
            noofrec: 10,
            comments: "",
            clickadvice: false,
            productid: 0,
            generatedid: '',
            showname: false,
            isprint: 0,
            totalsalevalue: 0,
            totalremaining: 0,
            salefrom: '',
            saleto: '',
            categorylist: [],
            customername: '',
            mobileno: '',
            getTotalSale: 0,
            getTotalRemain: 0,
            serialno: '',
            selectedfy: ''
        }
    },
    computed: {
        ...mapGetters([
            'viewno', 'loggedinuser', 'edititem', 'totalsalecount', "accountstatus", "loanaccounttype", "referraluser", 'categories'
        ]),
        noofpages() {
            return Math.ceil(parseInt(this.totalsalecount) / parseInt(this.noofrec))
        },
    },
    watch: {
        $route: function (to, from) {
            console.log(to)
            console.log(from)
            if (to != from) {
                if (this.$route.params.id != null) {
                    //console.log(this.$route.params.id)
                    this.searchstring = this.$route.params.id
                    this.search();
                    this.searchdetails = false

                } else {
                    this.searchdetails = true
                    this.refresh();
                }
            }
        },
        // referraluser: function () {
        //     if (this.referraluser != null) {
        //         this.id = this.referraluser.generatedid
        //     }
        // }
    },
    mounted() {
        this.$store.dispatch("fetchcategories")
        if (this.$route.params.id != null) {
            this.searchstring = this.$route.params.id
            this.searchdetails = false
            //console.log(this.$route.params)
            this.search();
        } else {
            this.refresh();
            this.searchdetails = true
        }
        this.$store.dispatch("fetchattributes")
        this.initialize()
        // this.fetchTotalSale()
    },
    methods: {
        initialize() {
            // let today = new Date()
            // let previousMonth = today.getMonth() - 1
            // this.salefrom = new Date(today.getFullYear(), previousMonth, today.getDate()).toISOString().slice(0, 10);
            // this.saleto = today.toISOString().slice(0, 10)
            setTimeout(() => {
                if (this.categories.length > 0) {
                    this.categorylist = this.categories.filter(a => a.parent_id > 0)
                } else {
                    this.categorylist = this.categories
                }
            }, 1000)
        },
        submitstatus() {
            if (this.accountstatus != null) {
                let param = {
                    comments: this.comments, loanaccountid: this.accountstatus.loandetails.id,
                    accountstatus: this.loanaccounttype
                }
                window.axios.post('api/account/closedsale/update', param)
                    .then((response) => this.processcommentResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    })
            }

        },
        processcommentResponse() {
            this.$notify({ text: 'Account Closed Successfully', type: 'Success' })
            this.$modal.hide('closedsaleaccount');
            this.comments = ''
            this.refresh();
        },
        processAttributes(data) {
            this.$store.commit('assignattributes', data);
        },
        close() {
            this.$modal.hide('closedsaleaccount')
            this.comments = ""
        },
        setaccounttype() {
            if (this.loanaccounttype == 5) {
                return "Closed Accounts"
            }
        },
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        initializevalue() {
            this.searchstring = ''
            this.productid = 0
            this.generatedid = ''
            this.customername = ''
            this.mobileno = ''
            this.searchdetails = true,
                this.serialno = ''
        },
        refresh() {
            this.initializevalue()
            // console.log("total sale", this.totalsalecount)
            let orders = [];
            orders.push("transactiondate")
            this.$store.commit("assignloadingstatus", 1)
            let param = {
                todate: this.todate, fromdate: this.fromdate, orderby: orders, noofrec: this.noofrec ?? 100,
                currentpage: this.currentpage ?? 1, customername: this.customername, mobile: this.mobileno
            }
            window.axios.post('api/saletransactions/datewise', param)
                .then(response => this.processResponse(response.data))
                .catch(error => console.log(error));

            window.axios.post('api/saletransactions/datewise/count', param)
                .then(response => this.countresponse(response.data))
                .catch(error => console.log(error));

            this.$store.dispatch("fetchemployees")
            // this.$store.dispatch("fetchtotalsalecount")
            this.fetchTotalSaleValue(param)
        },
        countresponse(data) {
            this.$store.commit('assigntotalsalecount', data)
        },
        processResponse(data) {
            this.$store.commit("assignloadingstatus", 0)
            this.transactiondetails = data;
            // this.getTotalSaleRemaining(data)
        },
        search() {
            this.$store.commit("assignloadingstatus", 1)
            let param = {
                fromdate: this.fromdate, todate: this.todate, noofrec: this.noofrec ?? 100, currentpage: this.currentpage ?? 1,
                searchstring: this.searchstring, productid: this.productid, generatedid: this.generatedid,
                customername: this.customername, mobile: this.mobileno, serialno: this.serialno
            }
            this.$http.post('api/saletransactions/datewise', param)
                .then((response) => this.processResponse1(response.data))
                .catch((err) => {
                    console.log(err)
                });
            window.axios.post('api/saletransactions/datewise/count', param)
                .then(response => this.countresponse(response.data))
                .catch(error => console.log(error));

            this.fetchTotalSaleValue(param)
        },
        processResponse1(data) {
            this.$store.commit("assignloadingstatus", 0)
            if (data != null) {
                this.transactiondetails = data;
                // this.getTotalSaleRemaining(data)
            } else {
                this.$notify({
                    text: 'Data Not Found',
                    type: 'error',
                    top: '100',
                    duration: '80000',
                    speed: '800',
                    closeOnClick: false,
                    position: 'center'
                })
            }
        },



        processUpdateResponse(data) {
            alert("success", data)
            this.$store.commit("assignloadingstatus", 0)
        },
        transactiondetailsmodal(item) {
            let param = { transactionid: item.id };
            window.axios.post('api/stransaction/details', param)
                .then(response => this.processDetailResponse(response.data))
                .catch(error => console.log(error));
            this.$store.commit("assignloadingstatus", 1)
        },
        processDetailResponse(data) {
            this.details = data.transactiondetails
            this.id = data.transaction.id
            this.$store.commit("assignloadingstatus", 0)
        },


        back() {
            this.$store.commit('assignviewno', 1)
            this.$children.localviewno = 1
            // this.refresh();
            this.$store.commit('assigntransaction', null)
            this.$store.commit('assignedititem', null)
            this.$store.commit('assigncartitems', [])
        },
        referralSearch: debounce(function () {
            let param = { generatedid: this.generatedid }
            this.$http.post('api/user/getuserbygeneratedid', param)
                .then(response => this.processUserResponse(response.data))
                .catch(error => console.log(error));
        }, 1000),
        // referralSearch(e) {
        //     this.showname=true
        //     e.target.value=e.target.value.toUpperCase()
        //         clearTimeout(this.debounce)
        //         this.debounce = setTimeout(() => {
        //             this.referralsearchajax();
        //         }, 600)
        // },
        processUserResponse(data) {
            // if (Object.keys(data).length > 0) {
            //     this.$store.commit('assignreferraluser', data)
            // }
            this.$store.commit('assignreferraluser', data)

        },
        cancel() {
            this.refresh();
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        hasDownloaded() {
            this.$parent.generate = false
            this.isprint = 0
        },
        generateReport() {
            this.isprint = 1
            this.$refs.html2Pdf.generatePdf()
        },
        // fetchTotalSale() {
        //     const first = new Date(this.salefrom);
        //     const second = new Date(this.saleto);
        //     let premonth = first.getMonth()
        //     let postmonth = second.getMonth()
        //     let diff = 0
        //     if (premonth < postmonth) {
        //         diff = postmonth - premonth
        //     } else {
        //         diff = premonth - postmonth
        //     }
        //     if (diff == 0 || diff == 1) {
        //         if (this.salefrom != '') {
        //             this.$store.commit('assignloadingstatus', 1)
        //             let param = { fromdate: this.salefrom, todate: this.saleto }
        //             window.axios.post('api/total/sale/fetch', param)
        //                 .then((response) => this.processTotalSaleResponse(response.data))
        //                 .catch((error) => console.log(error))
        //         }
        //     } else {
        //         this.$notify({ text: 'Data can display till one month', type: 'warn' })
        //     }
        // },
        fetchTotalSaleValue(param) {
            window.axios.post('api/total/sale/fetch', param)
                .then((response) => this.processTotalSaleResponse(response.data))
                .catch((error) => console.log(error))
        },
        processTotalSaleResponse(data) {
            console.log(data)
            this.totalsalevalue = data.sale
            this.totalremaining = data.remaining
            this.$store.commit('assignloadingstatus', 0)

        },
        clearAll() {
            this.fromdate = ''
            this.todate = ''
        },
        // getTotalSaleRemaining(transaction) {
        //     if (transaction.length > 0) {
        //         let temp = 0
        //         let remain = 0
        //         for (let i = 0; i < transaction.length; i++) {
        //             if (transaction[i].amtafterdiscount > 0) {
        //                 temp = temp + parseFloat(transaction[i].amtafterdiscount)
        //             } else {
        //                 temp = temp + parseFloat(transaction[i].amountwithtax)
        //             }
        //             if (transaction[i].loandetails != null) {
        //                 if (parseFloat(transaction[i].loandetails.remainingamount) > 0) {
        //                     remain = remain + parseFloat(transaction[i].loandetails.remainingamount)
        //                 }
        //             }
        //         }
        //         this.getTotalSale = Math.round(temp)
        //         this.getTotalRemain = Math.round(remain)
        //     }
        // },
        printTransaction() {
            if (this.fromdate == "") {
                this.$notify({ text: 'Select From Date', type: 'warn' })
            } else if (this.todate == '') {
                this.$notify({ text: 'Select To Date', type: 'warn' })
            } else {
                this.$store.commit('assignloadingstatus', 1)
                window.axios({
                    url: 'https://cn373vzw7i.execute-api.ap-south-1.amazonaws.com/api/transactiondetails/saletranpdf?fromdate=' + this.fromdate + '&todate=' + this.todate + '&searchstring=' + this.searchstring,
                    method: 'GET',
                    responseType: 'blob',
                })
                    .then((response) => {
                        this.$store.commit('assignloadingstatus', 0)
                        this.clearAll()
                        console.log("Response", response)
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'file.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            }


        },

    }
}
</script>