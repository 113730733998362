<template>
    <div class="col-md-12 p-0">
        <div class="col-md-12 mt-20">
            <div class="col-md-12 row">
                <div class="col-md-9">
                    <h3 class="page-header">
                        <i class="fa fa fa-rupee animated bounceInDown show-info" style="font-size: 24px;"></i>
                        Salary Slip
                    </h3>
                </div>
                <!-- <div class="col-md-3"  >
                    <button class="btn btn-success" >+ Add New</button>
                    <button class="btn btn-info ml-10" >Refresh</button>
                </div> -->
            </div>
        </div>
        <div class="col-md-12 p-0">
            <div class="">
                <table class="table ">
                    <thead>
                        <th>S.No.</th>
                        <th>Employee Name</th>
                        <th>Working Days</th>
                        <th>Basic</th>
                        <th>Advance</th>
                        <th>Fuel</th>
                        <th>Add. Sale Inc.</th>
                        <th>Leaves</th>
                        <th>Delivery</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in salaryslipdata" :key="item.id">
                            <td>{{ index + 1 }}</td>
                            <td>{{ getEmployeeName(item)??'' }}</td>
                            <td>{{ item.workingdays ?? '' }}</td>
                            <td>{{ item.basic ?? 0 }}</td>
                            <td>{{ item.advance ?? 0 }}</td>
                            <td>{{ item.fuel ?? 0 }}</td>
                            <td>{{ item.addsaleincentive ?? 0 }}</td>
                            <td>{{ item.leaves??0 }}</td>
                            <td>{{ item.delivery??0 }}</td>
                            <td>{{ item.totalamount ?? 0 }}</td>
                            <td>
                                <span v-if="item.status==0">Unpaid</span>
                                <span v-if="item.status==1">Paid</span>
                            </td>
                            <td>
                                <button class="badge bg-primary" v-if="item.status==0" @click="editSlip(item)">Edit</button>
                                <button class="badge bg-primary ml-5" @click="exportExcel(item)">Export </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'


export default {
    data(){
        return {
            salaryslipdata: [],
        }
    },
    mounted(){
        this.$store.dispatch('fetchemployees')
        this.refresh()
    },
    computed: {
        ...mapGetters([
            'employees'
        ])
    },
    methods: {
        refresh() {
            this.$store.commit('assignloadingstatus', 1)
            axios.post('api/salary/slip/fetch')
                .then((response) => {
                    if (response.data.salaryslips?.length > 0) {
                        this.salaryslipdata = response.data.salaryslips
                    }
                    this.$store.commit('assignloadingstatus', 0)

                }).catch((error) => console.log(error))
        },
        getEmployeeName(item) {
            let find = this.employees.find(o => o.id == item.userid)
            return find?.name ?? ''
        },
        editSlip(item){
            console.log(item)
            this.salaryData = item
            this.$store.commit('assignedititem',item)
            this.$router.push('/salary-slip-form')
        },
        exportExcel(item) {
            let param = {
                id: item.id
            }
            this.$http({
                url: 'api/salary/slip/export',
                method: 'post',
                responseType: 'blob',
                data: param,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    mode: 'no-cors'
                }
            })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'salary_slip.xlsx'
                    link.click()
                    this.flagexl = 0
                })
        }
    }
}
</script>