<template>
    <tbody class="table-font">
        <tr >
            <td>{{index+1}}</td>
            <td><a v-if="item.loandetails!=null" href="#" @click="loandetails(item.loandetails.id)">
                    <div >
                        <span style="font-size:11px"> {{item.loandetails.manualid}}</span>
                    </div>
                </a>
            </td>
            <td>{{item?.usersequence}}</td>
            <td><div v-if="item.user!=null && item.customername==null">{{item.user.generatedid}}</div>
                <div v-else>{{item.customername}}</div>
            </td>
            <td>{{ item.user==null?'':item.user.name }}</td>
            <td>
                <div v-if="item?.user?.mobiles!=null">
                    <div v-for="val in item.user.mobiles" :key="val.id">
                        <span>{{ val.mobile }}</span>
                    </div>
                </div>
            </td>
            <td>
                <div v-for="(value,index) in item.items" :key="value.id">
                    <!-- <span style="text-transform: capitalize;">{{ value.product.displayproduct }}</span><br> -->
                    <span >{{ index+1 }}. </span><span>{{ getProductName(value) ?? '' }}</span><br> <span> {{ value.product?.modelno }} </span><br>
                    
                        <!-- ({{ value.product?.category?.name }}) -->
                    
                    <!-- <div v-for="attribute in value.product.attribute" :key="attribute.id">
                        <span> {{ getcompany(attribute.attribute_value_ids) }}</span>
                    </div> -->
                </div>
            </td>
            <td>
                <span v-if="item.loandetails!=null">{{ parseInt(item.loandetails?.billedamount)+parseFloat(getEmiCost(item)) }}</span>
                <span v-else>{{ parseInt(item?.amountwithtax) }} </span> 
            </td>
            <td>
                <div v-if="item">
                    <span class="badge bg-seagreen">{{  item.amtafterdiscount > 0 ? Math.round(item.amtafterdiscount) : Math.round(item.amountwithtax) ?? 0}}</span>
                </div>
            </td>
            <td><span class="badge bg-danger">{{ item.loandetails?.remainingamount ?? 0 }}</span></td>
            <td><span v-if="item.saleby!=null">{{item.saleby.name}}</span></td>
            <td v-if="item.loandetails!=null">EMI</td>
            <td v-else>Cash</td>
            <td>{{ moment(item.transactiondate).format('DD-MM-YYYY') }}</td>
            <td><div v-if="item.advisor!=null">{{ item.advisor?.name }}<br>{{ item.advisor?.generatedid }}</div></td>
            <td><div v-if="item.enteredby!=null">{{item.enteredby.name}}<br>
                {{moment(item.created_at).format('DD-MM-YYYY')}}<br>
                {{moment(item.created_at).format('h:m:s A')}}</div>
            </td>
            <td><span v-if="item.loandetails!=null">{{setaccounttype(item)}}</span></td>
            <td v-if="loginusercheck(109)">
                <span v-if="(item.loandetails!=null && item.loandetails?.accountstatus==0 ) || item.loandetails==null">
                    <button class="btn btn-primary-edit" @click="transactionedit(item)"><i class="fa fa-edit"></i></button>
                </span>
                <span v-else>.....</span>
            </td>
            <td v-if="loginusercheck(110)">
                <span v-if="(item.loandetails!=null && item.loandetails?.accountstatus==0) || item.loandetails==null">
                    <button class="btn btn-primary-print" @click="print2(item)"><i class="fa fa-print" style="font-size:12px"></i></button>
                </span>
                <span v-else>.....</span>
            </td>
            <td v-if="loginusercheck(146)">
                <span v-if="item.loandetails!=null && item.loandetails.accountstatus==0">
                    <div class="form-group">
                        <select class="form-control" v-model="item.loandetails.accountstatus" @change="accountchange(item)">
                            <option :value="0">Due Acc.</option>
                            <option :value="1">Clear Acc</option>
                            <option :value="2">Deafulter Acc</option>
                            <option :value="3">Legal Acc</option>
                            <option :value="4">Concession Acc</option>
                        </select>
                    </div>
                </span>
                <span v-else>.....</span>
            </td>
            <td v-if="loginusercheck(145)">
                <button class="btn btn-danger" @click="deleteitem(item)">
                    <i class="fa fa-remove"></i></button>
            </td>
        </tr>
        <div v-if="page1(index)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>

    </tbody>
</template>
<script>
import moment from "moment";
import Constants from "../../components/utilities/Constants.vue";
import { mapGetters } from "vuex";
export default {
    mixins:[Constants],
    props:["item","index"],
    data() {
        return {
            accounttype:'',
            moment:moment
        }
    },
    computed:{	   
        ...mapGetters(["attributes",'categories']),
    },
    mounted(){
        // console.log("new ",this.categories)
    },
    
    methods: {
        page1(index){
            if(index==3){
                return true
            }
            if((index-3)%6==0){
                return true
            }
            
            return false
        },
        setaccounttype(item){
            if(item.loandetails!=null){
                if(item.loandetails.accountstatus==0){
                    return "Active"
                }else if(item.loandetails.accountstatus==1){
                    return "Clear Sale"
                }else if(item.loandetails.accountstatus==2){
                    return "Defaulter Sale"
                }else if(item.loandetails.accountstatus==3){
                    return "Legal Sale"
                }else if(item.loandetails.accountstatus==4){
                    return "Concesation Account"
                }else if(item.loandetails.accountstatus==5){
                    return "Closed Sale"
                }
            }
        },
        accountchange(item){
            console.log(item.loandetails.accountstatus)
            this.$store.commit("assignaccountstatus",item)
            this.$store.commit("assignloanaccounttype",item.loandetails.accountstatus)
            this.$modal.show('closedsaleaccount');
        },
        async print2(item) {
			console.log("Trying to print the pdf")
			let payload = {transactionid:item.id}
			await window.axios.post('https://ss.graddev.in/api/invoice1',payload,{
				responseType: 'blob',
			})
				.then(response => {
					console.log(response.data)
					var fileURL = window.URL.createObjectURL(new Blob([response.data]));
					var fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute('download', item.id+'.pdf');
					document.body.appendChild(fileLink);
					fileLink.click();
				})
				.catch(err=>{
					console.log(err)
				});
		},
        printtransaction(item){
            let param = {transactionid:item.id};
            window.axios.post('api/stransaction/details',param)
                .then(response=>this.processResponseData(response.data))
                .catch(error=>console.log(error));
        },
        processResponseData(data){
            //  console.log("data", data)
             this.$store.commit("assignloadingstatus",0)
            this.$store.commit("assigntransactiondetails",data.transactiondetails);
            this.$store.commit('assigntransaction',data.transaction);
            //this.$store.commit('assignbarcodeforproduct',data.barcode);
            //this.$router.push({name:"invoice"})
            this.$store.commit('assignviewno',4);
        },
        transactionedit(item){
            // console.log("item",item)
            if(item.loandetails!=null && item.loandetails.totalreceived > 0){
                alert("You cannot Edit transaction, As Payment has been made of these Emi's of LAN-"+item.loandetails.manualid+", Try out Reversing the Emi from the Account and then Try")
            }else{
                this.$store.commit('assigntransaction',item)
                this.$store.commit('assignedititem',item)
                this.saletransactionedit(item.id)
                this.$store.commit('assignviewno',3)
            }
        },
        
        loandetails(id){
            if(this.loginusercheck(111)){
                let param = {id:id}
                this.$http.post('api/loan/details',param)
                .then((response) => this.processloanResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            }else{
                alert("You Don't Have Right To Access")
            }
           
        },
        processloanResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assignedititem',data)
            this.$store.commit('assignviewno',2)
            this.$store.commit('assignidproofs',data.customer.idproofs==null?[]:data.customer.idproofs)
            this.$children.localviewno=1
        },
        deletesale(item){
            if(confirm("Do you really want to delete ?")==true){
                if(item.loandetails!=null && item.loandetails.totalreceived > 0){
                    alert("You cannot delete transaction, As Payment has been made of these Emi's of LAN-"+item.loandetails.manualid+", Try out Reversing the Emi from the Account and then Try")
                }else{
                    this.$store.commit('assignloadingstatus',1)
                    let param = {transactionid:item.id,trantype:item.trantype};
                    window.axios.post('api/transaction/delete',param)
                    .then(response=>this.processDeleteDetailsResponse(response.data,item))
                    .catch(error=>console.log(error));
                    this.$notify({text:"deleted",type:"warn"});
                }
            }
        },
        processDeleteDetailsResponse(data,item){
            this.$store.commit('assignloadingstatus',0)
			this.$notify({text:"deleted",type:"error"});
            this.$parent.transactiondetails
            const index = this.$parent.transactiondetails.findIndex(block => block.id === item.id);
            this.$parent.transactiondetails.splice(index,1)
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        deleted(){
            this.$parent.refresh()
        },
        getProductName(item){
            let val = this.categories.find(a=>a.id==item?.product?.category?.id) 
            return val.name
        },
        getEmiCost(item) {
            let items = item?.items
            let temp = 0
            let total = 0
            for(let i=0; i < items?.length; i++){
                let json = JSON.parse(items[i].product?.emi_cost)?.emi_cost
                temp = parseFloat(temp) + parseFloat(json)
            }
            if(temp > 0){
                total = parseFloat(item.loandetails?.numberofemi) *  parseFloat(temp)
            }
            return parseFloat(total).toFixed(2) ?? 0;
        },
        
        
    },
}
</script>