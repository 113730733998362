<template>
    <div>
        <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="true"
            margin="[10,10,10,10]" filename="Received Emi Report" :paginate-elements-by-height="1400" :pdf-quality="2"
            pdf-format="A4" pdf-orientation="portrait" pdf-content-width="auto" :manual-pagination="true"
            @progress="onProgress($event)" @beforeDownload="beforeDownload($event)" @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf">
            <section slot="pdf-content">
                <div class="col-md-12">
                    <div class="row" style="margin-top: 20px">
                        <div class="col-md-6">
                            <h3 class="page-header">
                                <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                                <span>{{ setheadding() }}</span>
                            </h3>
                        </div>
                        <div class="col-md-6 text-right" v-if="hide()==2 &&isprint == false">
                            <button @click="generateReport()" class="btn btn-xs pdf"
                                style="margin-right: 30px;"><i class="bx bxs-file-pdf" style="font-size:50px"></i></button>
                            <!-- <button @click="refresh()" class="btn btn-refresh">
                                <i class="fa fa-refresh"></i>
                            </button> -->
                        </div>

                    </div>
                    <div v-if="hide()==2 && isprint == false">
                        <div class="row" style="margin-left: 0px;">
                            <div class="col-md-4 row">
                                <label><b>Entry By</b></label>
                                <select v-model="entrybyid" class="form-control form-cascade-control input-small">
                                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
                                </select>
                            </div>
                           
                            <div class="col-md-4 row">
                                <label><strong>Entry Date : From</strong></label>
                                <input class="form-control form-cascade-control input-small" type="date"
                                    v-model="entrybydatefrom">
                            </div>
                            <div class="col-md-4 row">
                                <label><strong>Entry Date : To</strong></label>
                                <input class="form-control form-cascade-control input-small" type="date"
                                    v-model="entrybydateto">
                            </div>
                            <div class="col-md-4 row">
                                <label><b>Received By</b></label>
                                <select v-model="receivedbyid" class="form-control form-cascade-control input-small">
                                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-4 row">
                                <label>Payment Mode</label>
                                <select v-model="mode" class="form-control form-cascade-control input-small" >
                                    <option v-for="filter in registers" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
                                </select>

                            </div>
                            <div class="col-md-4 flex-around-row ">
                                <button class="btn btn-primary" @click="search()">Search</button>
                                <button class="btn btn-danger" @click="cancel()">Cancel</button>
                            </div>

                        </div>
                        <div class="row" >
                            <div class="col-md-4 total_div" >
                                <label>Total Received Amount : </label>
                                <span class="total">{{ this.totalreceivedamount }}</span>
                            </div>
                            <div class="col-md-4 total_div" >
                                <label>Total Received Count : </label>
                                <span class="total">{{ this.allpaymentscount }}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-12 table-responsive" style="padding:0px;">
                        <div class="panel">
                            <div class="panel-body table" style="overflow-x:auto;padding-left:0px">
                                <table class="table table-bordered table-font"
                                    style="margin-bottom:0;font-size:15px!important;">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Lan No.</th>
                                            <th>Customer Name</th>
                                            <th>Emi Amount</th>
                                            <th>Received Amount</th>
                                            <th>Received By</th>
                                            <th>EMI Date</th>
                                            <th>Entry Date</th>
                                            <th>Entry By</th>
                                            <th >Action</th>
                                        </tr>
                                    </thead>

                                    <tbody v-for="(item,index) in payments" v-bind:key="item.id">
                                        <tr>
                                            <td>{{ index+1 }}</td>
                                            <td>{{ item.loanemi!=null?item.loanemi.loanaccount.manualid:'' }}</td>
                                            <td>{{ item.customer!=null?item.customer.name:'' }}</td>
                                            <td>{{ item.loanemi!=null?item.loanemi.emiamount:'' }}</td>
                                            <td>
                                                <div>
                                                    <span>{{ item.loanemi!=null?item.loanemi.receviedamount:'' }}</span>
                                                    <br>
                                                    <span class="badge btn-view"  >{{ item.paymentregister.name }}</span>
                                                </div>
                                            </td>
                                            <td>{{ item.collectedby!=null?item.collectedby.name:'' }}</td>
                                            <td>{{ item.loanemi!=null?item.loanemi.emidate:'' }}</td>
                                            <td>{{ item.transactiondate }}</td>
                                            <td>{{ item.enteredby!= null ? item.enteredby.name : '' }}</td>
                                            <td>
                                                <div v-if="hide()==1">
                                                    <div v-if="item.loanemi.disablereverse == 1">
                                                       <span style="color: green;font-weight: bold;">Approved</span>
                                                    </div>
                                                    <div v-else>
                                                        <button type="button" class="btn btn-success" @click="approve(item)">Approve </button>
                                                    </div>
                                                    
                                                </div>
                                                <div v-if="hide()==2">
                                                    <div v-if="item.rollback==0">   
                                                        <span style="color: green;">Emi Paid</span>
                                                    </div>
                                                    <div v-if="item.rollback==1">
                                                        <span style="color: red;font-weight: bold;">Reversed Emi</span>
                                                    </div>
                                                </div>
                                               
                                                
                                            </td>
                                        </tr>  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div v-if="isprint==false" class="col-md-12" style="margin-top: 20px">
                        <nav aria-label="Page navigation example" style="text-align: right !important">
                            <paginate
                                style="margin: 0px !important; float: right"
                                :page-count="noofpages"
                                :click-handler="clickCallback"
                                :prev-text="'&laquo;'"
                                :next-text="'&raquo;'"
                                :container-class="'pagination'">
                            </paginate>
                        </nav>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import { mapGetters } from 'vuex'
import Constants from "../../components/utilities/Constants.vue";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    mixins: [Constants],
    components: { Paginate, VueHtml2pdf },
    data(){
        return {
            currentpage: 1,
            noofrec: 10,
            payments: [],
            totalcommentcount: 0,
            entrybyid: 0,
            receivedbyid:0,
            entrybydatefrom: '',
            entrybydateto: '',
            entryby:'',
            isprint: false,
            progress: 0,
            mode:0,
            totalreceivedamount:0,
        }
    },
    computed: {
        ...mapGetters([
            'employees','allpaymentscount','registers'
        ]),
        noofpages() {
            return Math.ceil(parseInt(this.allpaymentscount) / parseInt(this.noofrec))
        },
    },
    watch: {
        '$route.params.isdraft'() {
            this.search()
        },
        
    },
    mounted() {
         this.search();
         this.hide();
        
    },

    methods: {

        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.search();
        },
       
        search() {
            this.$store.commit('assignloadingstatus',1);
            this.$store.dispatch("fetchemployees");
            this.$store.dispatch("fetchregisters")
            let param = { entrybyid: this.entrybyid, receivedbyid:this.receivedbyid, currentpage: this.currentpage, noofrec: this.noofrec,
                mode: this.mode, entrybydatefrom: this.entrybydatefrom, entrybydateto: this.entrybydateto }
            if(this.$route.params.isdraft=='emistatus'){
                this.$http.post('api/payments/status', param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {console.log(err)});
            }else{
                this.$http.post('api/payments/records', param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {console.log(err)});
            }
           
            // this.$http.post('api/allpayments/fetch/count',param)
            //     .then(response=>this.processCountResponse(response.data))
            //     .catch(error=>console.log(error));
            // // this.initialize();
        },

        processDetailResponse(data) {
            this.$store.commit('assignloadingstatus',0);
            this.$store.commit('assignallpaymentscount',data.count);
            this.payments = data.payments
            this.totalreceivedamount=data.totalamount
        },
        // processCountResponse(data){
        //     
        // },
        cancel(){
            this.entryby=''
            this.entrybydatefrom=''
            this.entrybydateto=''
            this.receivedbyid=0
            this.mode=0
        },
        // reverseemi(emiid){
        //     this.$store.commit('assignloadingstatus',1)
        //     if(confirm("Do you really want to delete ?")==true){
        //         this.$notify({
        //         text:'Payment Reversed',
        //         type:'warn',
        //         duration:'8000',
        //         speed:'800',
        //     })
        //         let param={emiid:emiid}
        //         window.axios.post('api/loan/loanemidelete',param)
        //             .then(response=>this.processResponsedelete(response.data))
        //             .catch(error=>this.reverseError(error));
        //     }
        //     else{
        //         this.$store.commit('assignloadingstatus',0)
        //     }
        // },
        processResponsedelete(data){
            if(data==true){
                this.$notify({
                    text:'Payment Reversed Successfully',
                    type:'success',
                    duration:'8000',
                    speed:'800',
                    
                })
                this.$store.commit('assignloadingstatus',0)
                // this.$store.commit("assignviewno",1)
                this.search();
            }
            this.$store.commit('assignloadingstatus',0)
        },
        getemiid(item){
            let data = item.loanemi.id;
            return data;
        },
        generateReport() {
            this.$store.commit('assignloadingstatus', 1)
            this.isprint = true
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded() {
            this.$store.commit('assignloadingstatus', 0)
            this.isprint = false
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        page1(index) {
            // (index!=0 && index==6 && index!=8) || (index!=0 && index!=6 && index%6==0)
            if (index != 0 && index == 6) {
                return true
            }
            // if(index!=0 &&  index!=6 && index%6==0){
            if ((index - 6) % 9 == 0) {
                return true
            }
            return false
        },
        setheadding() {
            if (this.hide()==1) {
                return "Received Emi Status"
            } else if (this.hide()==2) {
                return "Received Emi Report"
            }
        },
        hide(){
            if (this.$route.params.isdraft=='emistatus') {
                return 1;
            } else if (this.$route.params.isdraft=='emireport') {
                return 2;
            }
        },
        approve(item){
            this.$store.commit('assignloadingstatus',1);
            let emiid=item.reftransactionid;
            let param = {
                emiid: emiid,status:1
            }
            window.axios.post('api/update/status',param)
                .then((response)=>this.processUpdate(response.data))
                .catch((error)=>console.log(error)) 
        },
        processUpdate(data){
            this.$store.commit('assignloadingstatus',1);
            this.search()
            let abcd = data;
            return abcd;
        }
        
    },
}
</script>
<style>

.total_div {
    margin: 10px;
    border: 1px solid silver;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px gray;
}
</style>



